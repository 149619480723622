import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";
import { useAuth } from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";
import '../login/login.css';


const Login = () => {
  const { login } = useAuth();
  const letsGo = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = { email, password };
    const loginSuccess = await login(userData);

    if (loginSuccess) {
      const previousLocation = sessionStorage.getItem("previousLocation");
      if (previousLocation) {
        letsGo(previousLocation);
        sessionStorage.removeItem("previousLocation");
        window.location.reload();
      } else {
        letsGo("/");
        window.location.reload();
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
        <img src="/Rectangle 20.png" alt="Login Visual" />
      </div>
      <div className="login-form">
        <img src={MASTER_LOGO} alt="logo" className="logo" />
        <h2>Login</h2>
        <p>Login to access your travelwise account</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="options">
            <label>
              <input type="checkbox" /> Remember me
            </label>
            <a href="/forgotpassword">Forgot Password?</a>
          </div>
          <button type="submit" className="login-button">Login</button>
          <p className="signup-link">
            Don't have an account? <a href="/signup">Sign up</a>
          </p>
          <p>Or login with</p>
          <div className="social-login">
            <button className="facebook">F</button>
            <button className="google">G</button>
            <button className="apple">A</button>
          </div>
        </form>
      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Login;

/* CSS */


