import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import { Table } from "react-bootstrap";
import "./profile.css";

const Profile = () => {
  const { user } = useAuth();
  const [userEventData, setUserEventData] = useState([]);

  const [formData, setFormData] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phoneNo: "",
    dateOfBirth: "",
    organization: "",
    fathersName: "",
    guardiansPhoneNo: "",
    address: "",
    pinCode: "",
  });

  console.log("formData:", formData);

  useEffect(() => {
    setFormData({
      _id: user?._id || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      gender: user?.gender || "",
      phoneNo: user?.phoneNo || "",
      dateOfBirth: formatISODate(user?.dateOfBirth) || "", // Updated date format
      organization: user?.organization || "",
      fathersName: user?.fathersName || "",
      guardiansPhoneNo: user?.guardiansPhoneNo || "",
      address: user?.address || "",
      pinCode: user?.pinCode || "",
    });
  }, [user]);

  // Define a function to format the date
  function formatISODate(isoDate) {
    if (!isoDate) return ""; // Handle cases where isoDate is null or undefined

    const dateObject = new Date(isoDate);
    return dateObject.toISOString().split("T")[0];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/user-profile`,
        formData,
        {
          headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
        }
      );

      if (res.status === 200) {
        toast.success("Profile Updated Successfully");
        window.location.reload();
      } else {
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("An error occurred while updating the profile");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/get-event-registrations`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("accessToken")}`,
            },
          }
        );
        if (response?.status == 200) {
          setUserEventData(response?.data);
          console.log( "userevent",response?.data);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="register">
      <div className="row">
        <div
          className="container"
          style={{ background: "white", padding: "30px",marginTop:"10px" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="col">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="text-right">Profile Settings</h2>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="row ">
                      <div className="col-md-6 mt-3">
                        <label className="labels">FirstName</label>

                        <input
                          type="text"
                          className="form-control"
                          value={formData?.firstName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label className="labels">LastName</label>

                        <input
                          type="text"
                          className="form-control"
                          value={formData?.lastName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="labels">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={formData.email}
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">Phone Number</label>

                      <input
                        type="number"
                        className="form-control"
                        value={formData?.phoneNo}
                        onChange={(e) =>
                          setFormData({ ...formData, phoneNo: e.target.value })
                        }
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">DOB</label>

                      <input
                        type="date"
                        className="form-control"
                        value={formData?.dateOfBirth}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            dateOfBirth: e.target.value,
                          })}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="labels">Gender</label>

<br/>
                        <select
                          className="select-option"
                          id="gender"
                          name="gender"
                          value={formData?.gender} // Update with the appropriate state variable
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              gender: e.target.value,
                            })
                          }
                        
                        >
                          <option value="">Select</option>

                          <option value="male">Male</option>

                          <option value="female">Female</option>
                        </select>
                      </div>
                    {/* <div className="col-md-12 mt-3">
                      <label className="labels">Gender</label>

                      <input
                        type="text"
                        className="form-control"
                        value={formData?.gender}
                        onChange={(e) =>
                          setFormData({ ...formData, gender: e.target.value })
                        }
                      />
                    </div>
                  </div> */}
</div>
                  <div className="col-md-6">
                    <div className="col-md-12 mt-3">
                      <label className="labels">Organzation</label>

                      <input
                        type="text"
                        className="form-control"
                        value={formData?.organization}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            organization: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">Father's Name</label>

                      <input
                        type="text"
                        className="form-control"
                        value={formData?.fathersName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            fathersName: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">Gardian's Phone No.</label>

                      <input
                        type="number"
                        Name
                        className="form-control"
                        value={formData?.guardiansPhoneNo}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            guardiansPhoneNo: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">Address</label>

                      <input
                        type="text"
                        className="form-control"
                        value={formData?.address}
                        onChange={(e) =>
                          setFormData({ ...formData, address: e.target.value })
                        }
                      />
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="labels">Zip Code</label>

                      <input
                        type="number"
                        className="form-control"
                        value={formData?.pinCode}
                        onChange={(e) =>
                          setFormData({ ...formData, pinCode: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="profile" style={{ textAlign: "center" }}>
                  <button className="profile-button" type="submit">
                    Save Profile
                  </button>
                </div>
              </div>

              <div className="p-3 ">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="text-right"> Registered Events</h2>
                </div>
                <div style={{overflow:"auto"}}>  
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    className="my_event_table"
                  >
                    <thead>
                      <tr>
                        <th>S.NO.</th>

                        <th>Participant Name</th>

                        <th>Category</th>

                        <th>Title</th>

                        <th>Start Date</th>

                        <th>End Date</th>
                         
                        

                      
                      </tr>
                    </thead>

                    <tbody>
                      {userEventData?.map((event, index) => (
                        <tr key={event?._id}>
                          <td>{index + 1}</td>

                          <td>{event?.firstName}&nbsp;{event?.lastName}</td>

                          <td>{event?.events[0]?.category}</td>

                          <td>{event?.events[0]?.title}</td>

                           <td>{formatDate(event?.events[0]?.startDate)}</td>

                           <td>{formatDate(event?.events[0]?.endDate)}</td>
                          

                          
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Profile;
