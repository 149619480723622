import React, { useState } from "react";
import "../LandingPage/css/NewsLetter.css";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleJoin = async () => {
    if (!email) {
      setMessage("Please enter a valid email.");
      setShowPopup(true);
      return;
    }
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/newsletter-user/join-newsletter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
  
      const data = await response.json(); 
  
      if (response.ok) {
        setMessage("Successfully joined the newsletter!");
        setEmail("");
      } else {
        if (data.message === "Email is already subscribed") {
          setMessage("This email is already subscribed to the newsletter.");
        } else {
          setMessage("Failed to join. Please try again.");
        }
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  
    setShowPopup(true);
  };
  

  return (
    <div id="newsletter" className="gaming-community">
      <div className="email-form">
        <input
          type="email"
          placeholder="Subscribe to our newsletter"
          className="email-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="join-button" onClick={handleJoin}>
          Join
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{message}</p>
            <button className="close-button" onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsLetter;
