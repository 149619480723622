import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./registers.css";

const Personal = () => {
  const letsGo = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const { eventId } = useParams();

  const [showPopup, setShowPopup] = useState(false);
  const [registrationId, setRegistrationId] = useState(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      letsGo("/login");
    }
  }, [isAuthenticated, letsGo]);

  

  const [formData, setFormData] = useState({
    userId: user?._id || "",
    eventId: eventId,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    gender: user?.gender || "",
    phoneNo: user?.phoneNo || "",
    dateOfBirth: formatISODate(user?.dateOfBirth) || "",
    organization: user?.organization || "",
    fathersName: user?.fathersName || "",
    guardiansPhoneNo: user?.guardiansPhoneNo || "",
    address: user?.address || "",
    pinCode: user?.pinCode || "",
    tshirtSize: user?.tshirtSize || "",
    sign: user?.firstName || "",
    liability: false,
  });

  function formatISODate(isoDate) {
    if (!isoDate) return "";
    return new Date(isoDate).toISOString().split("T")[0];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/create-registration`,
        { ...formData }
      );
      if (res?.status === 200) {
        // toast.success("Registration Successful! Redirecting to payment...");
        setShowPopup(true); 
        setRegistrationId(res?.data?.registration?._id);
       
       
      } else {
        toast.error("Please fill all the fields");
      }
    } catch (error) {
      toast.error("Registration failed");
    }
  };
  

  return (
    <div
      style={{ backgroundImage: `url("/Rectangle 2.png")`, paddingTop: "10px" }}
    >
      <h2 className="form-header">Event Registration</h2>
      <div className="containerEvent ">
        <div className="form-container">
          <div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="form-section">
                <label className="form-label">First Name:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.firstName}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
              </div>
              <div className="form-section">
                <label className="form-label">Last Name:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.lastName}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </div>
              <div className="form-section">
                <label className="form-label">Email:</label>
                <input
                  type="email"
                  className="form-input"
                  value={formData.email}
                  required
                  disabled
                />
              </div>
              <div className="form-section">
                <label className="form-label">Phone No:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.phoneNo}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNo: e.target.value })
                  }
                />
              </div>
              <div className="form-section">
                <label className="form-label">Date of Birth:</label>
                <input
                  type="date"
                  className="form-input"
                  value={formData.dateOfBirth}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, dateOfBirth: e.target.value })
                  }
                />
              </div>
              <div className="form-section">
                <label className="form-label">Gender:</label>
                <select
                  className="form-select"
                  value={formData.gender}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="form-section">
                <label className="form-label">T-Shirt Size:</label>
                <select
                  className="form-select"
                  value={formData.tshirtSize}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, tshirtSize: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                </select>
              </div>
              <div className="form-section">
                <label className="form-label">Address:</label>
                <textarea
                  className="form-textarea"
                  value={formData.address}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                ></textarea>
              </div>
              {/* <div className="form-group">
                        <input
                          required
                          type="text"
                          name="fathersName "
                          placeholder="Father's Name *"
                          className="form-control"
                          value={formData?.fathersName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              fathersName: e.target.value,
                            })
                          }
                        />
                      </div> */}
              {/* add fathersname */}
              <div className="form-section">
                <label className="form-label">Father's Name:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.fathersName}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, fathersName: e.target.value })
                  }
                />
              </div>
              <div className="form-section">
                <label className="form-label">Pin Code:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.pinCode}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, pinCode: e.target.value })
                  }
                />
              </div>
              {/*add guardiansPhoneNo */}
              <div className="form-section">
                <label className="form-label">Guardian's Phone No:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.guardiansPhoneNo}
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      guardiansPhoneNo: e.target.value,
                    })
                  }
                />
              </div>
              {/*add organization */}
              <div className="form-section">
                <label className="form-label">Organization:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.organization}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, organization: e.target.value })
                  }
                />
              </div>
              <div className="form-checkbox-group">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={formData.liability}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, liability: e.target.checked })
                  }
                />
                <span
                  onClick={() =>
                    setFormData({ ...formData, liability: !formData.liability })
                  }
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  I accept the Terms of Service and acknowledge the Liability
                  Waiver
                </span>
              </div>

              {formData.liability && (
                <p className="liability-text">
                  I, the undersigned, being aware of my own health and physical
                  condition, and having knowledge that my participation in any
                  event that requires physical activity may be injurious to my
                  health, am voluntarily participating in a physical activity.
                  Having such knowledge, I hereby acknowledge this release, the
                  organizers of VectoG, any representatives, agents, and
                  successors from liability for accidental injury or illness
                  which I may incur as a result of participating in the said
                  event. I hereby assume all risks connected therewith and
                  consent to participate in said event. I agree to disclose any
                  physical limitations, disabilities, ailments, or impairments
                  which may affect my ability to participate in said event that
                  requires physical activity.
                </p>
              )}
              <div className="form-section">
                <label className="form-label">Sign:</label>
                <input
                  type="text"
                  className="form-input"
                  value={formData.sign}
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, sign: e.target.value })
                  }
                />
              </div>
              <button className="submit-button" type="submit">
                Register now
              </button>
            </form>
          </div>
          <div>
            <img src="/Rectangle 10.png" alt="Event" className="event-image" />
          </div>
        </div>
        <ToastContainer position={toast.POSITION.TOP_CENTER} />
      </div>
       {/* Success Popup */}
       {showPopup && (
  <div className="popup-container">
    <div className="popup">
      <img src="/Group 48096450.png" alt="Success" className="popup-icon" />
      <p className="popup-message">
        Registration successful! Click below to proceed to payment.
      </p>
      <button 
        className="popup-button" 
        onClick={() => letsGo("/userRegistered/" + registrationId)}
      >
        Proceed to Payment
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Personal;
