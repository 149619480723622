import React, { useEffect, useState } from "react";
import "../../App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import Table from "react-bootstrap/Table";

const NewsletterSubscriberList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    if (!isAdmin) {
      navigate("/login");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(
          "https://api.vectog.com/api/newsletter-user/get-all-joined-users",
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        if (response?.data?.JoinedUsers) {
          setSubscribers(response?.data?.JoinedUsers);
        } else {
          console.error("Subscribers not found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscribers();
  }, []);

  return (
    <div className="container">
      <div className="event_div">
        <h2>Newsletter Subscribers</h2>
        <Table striped bordered hover size="sm" className="my_event_table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Subscribed At</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            ) : subscribers.length > 0 ? (
              subscribers.map((subscriber, index) => (
                <tr key={index}>
                  <td>{subscriber.email}</td>
                  <td>{new Date(subscriber.createdAt).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No subscribers found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default NewsletterSubscriberList;
