import React from "react";
import "../../App.css"; // Importing CSS file

const Vision = () => {
  return (
    <div className="vision-container container">
      {/* Left Section - Image */}
      <div className="vision-image">
        <div className="image-container">
          <img src="/333 (2).png" alt="Vision" />
        </div>
        <div className="image-shadow-border"></div>
      </div>

      {/* Right Section - Text Content */}
      <div className="vision-text">
        <h2>Vision</h2>
        <p>
          An athlete aims to achieve success by performing at national and
          international levels. To achieve this dream, the athlete at the least
          requires sports science, coaching, and infrastructure. One or rather
          multiple aspects of these are either limited to access or non-existent
          in India. But as sports is being seen as a career by more and more
          people, the development is taking place, only at a very slow pace. The
          Indian population has the potential, what they need is a professional
          environment.
        </p>

        <p className="quote">
          “It is our vision at VectoG that we provide all the missing pieces and
          create an environment that will help every athlete reach his or her
          full potential.”
        </p>

        <p>
          To turn our vision into reality, we created five major departments.
          The creation of these departments originated from the necessity to
          achieve excellence at the highest level in sports.
        </p>

        <div className="shadow-border"></div>
      </div>
    </div>
  );
};

export default Vision;
