import React, { useState } from "react";
import "../../App.css"; // Importing CSS file
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/contacts/create-contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });
      
      const result = await response.json();
      if (response.ok) {
        toast.success("Your message has been sent successfully!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else {
        toast.error(result.message || "Failed to send message.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="contact" className="contact-container">
      <div className="contact-image">
        <img src="/unsplash_TyanDMPxwHc.png" alt="Contact" />
      </div>
      <div className="contact-content">
        <h2 style={{ paddingBottom: "30px" }}>Contact Us</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>Full Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          
          <label>Email</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          
          <label>Subject</label>
          <input type="text" name="subject" value={formData.subject} onChange={handleChange} required />
          
          <label>Message</label>
          <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
          
          <button type="submit" disabled={isSubmitting}>{isSubmitting ? "Sending..." : "Contact Us"}</button>
        </form>
      </div>
      <div className="contact-info">
        <p><strong>Contact</strong><br />hi@vectog.com</p>
        <p><strong>Based in</strong><br />Los Angeles, California</p>
      </div>
    </div>
  );
};

export default ContactUs;
