import React, { useEffect, useState } from "react";
import "../../App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";
import './Admin.css'
import Table from "react-bootstrap/Table";



const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  const letsGo = useNavigate();

  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    if (!isAdmin) {
      letsGo("/login");
    }
  }, [isAdmin, letsGo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.vectog.com/api/contacts/get-all-contacts`,
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        if (response?.data?.contacts) {
          setContacts(response?.data?.contacts);
          console.log(response?.data?.contacts);
        } else {
          console.error(
            "Users not found in the API response:",
          
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

console.log(contacts)


  return (
    <div className="container">

    <div className="event_div">

      <h2>Contact List</h2>
      
      <Table striped bordered hover size="sm" className="my_event_table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone No</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 ? (
              contacts.map((contact, index) => (
                <tr key={index}>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.message}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No contacts found.</td>
              </tr>
            )}
          </tbody>
        </Table>
     
    </div>
    </div>
  );
};

export default ContactList;
