/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/authContext.js";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import "./linkcontainer.css";

const LinksContainer = ({ hidden }) => {
  const { isAuthenticated, logout, eventDetails } = useAuth();
  const { user } = useAuth();

  // console.log(user);
  // console.log(eventDetails);

  // three dots functionalty && All Dropdowns here  Functinality
  const aboutItems = (
    <Menu>
      <Menu.Item key="Our Mission" className="on_hover_item">
      <a
  href="#about"
  className="dropdown_nav_anchor text_styling_anchor admin_styling"
>
  Our Mission
</a>

      </Menu.Item>
      <Menu.Item key="  Services" className="on_hover_item">
        <a
          href="#about"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Services
        </a>
      </Menu.Item>
      <Menu.Item key="Partners" className="on_hover_item">
        <a
          href="#about"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Partners
        </a>
      </Menu.Item>
    </Menu>
  );
  const upcomingEventsItem = (
    <Menu>
      <Menu.Item key="VectoGen" className="on_hover_item">
        <a
          href="/event/VectoGen"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          VectoGen
        </a>
      </Menu.Item>
      <Menu.Item key="VectoGames" className="on_hover_item">
        <a
          href="/event/VectoGames"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          VectoGames
        </a>
      </Menu.Item>
      <Menu.Item key="Seminars" className="on_hover_item">
        <a
          href="/event/Seminars"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Seminars
        </a>
      </Menu.Item>
    </Menu>
  );

  const items = (
    <Menu>
      {/* <Menu.Item key="login" className="on_hover_item">
        <a
          href="/login"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Login
        </a>
      </Menu.Item>
      <Menu.Item key="sign up" className="on_hover_item">
        <a
          href="/signup"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Sign Up
        </a>
      </Menu.Item> */}
      {/* <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/login"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          MEMBERSHIP
        </a>
      </Menu.Item> */}
    </Menu>
  );

  const adminMenu = (
    <Menu className="admin_menu">
      <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/admin/eventlist"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Events
        </a>
      </Menu.Item>
      <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/admin/users"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Users
        </a>
      </Menu.Item>
      <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/admin/registerdusers"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Registered User
        </a>
      </Menu.Item>
      <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/admin/contact-list"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Contact List
        </a>
      </Menu.Item>
      <Menu.Item key="membership" className="on_hover_item">
        <a
          href="/admin/newsletter-subscribers"
          className="dropdown_nav_anchor text_styling_anchor admin_styling"
        >
          Newsletter Subscribers
        </a>
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item key="profile" className="on_hover_item">
        <a href="/account" className="dropdown_nav_anchor text_styling_anchor">
          Profile
        </a>
      </Menu.Item>
      <Menu.Item key="logout" className="on_hover_item">
        {" "}
        <a
          href="/"
          className="dropdown_nav_anchor text_styling_anchor"
          onClick={logout}
        >
          Logout
        </a>
      </Menu.Item>
      {/* <Menu.Item key="membership" className="on_hover_item">
        <a href="/" className="dropdown_nav_anchor text_styling_anchor">
          MEMBERSHIP
        </a>
      </Menu.Item> */}
      <Menu.Item key="admin" className="on_hover_item">
        <>
          {user?.isAdmin ? (
            <Dropdown
              overlay={adminMenu}
              // trigger={["click"]}
            >
              <a
                href="/#"
                // onClick={(e) => {e.preventDefault();
                className="dropdown_nav_anchor text_styling_anchor"
              >
                Admin
              </a>
            </Dropdown>
          ) : null}
        </>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        css={styles}
        className={(hidden ? "hidden" : "") + " linksContainer"}
      >
        <div css={styles}>
          {/* <a
          href="/"
          // style={{ textDecoration: "none" }}
          className="dropdown_nav_anchor text_styling_anchor"
        >
          Home
        </a> */}

          <div className="dropdown">
            {" "}
            <Dropdown
              overlay={aboutItems}
              // trigger={["click"]}
            >
              <button
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  border: "none",
                }}
                href="/#"
                // onClick={(e) => {e.preventDefault();
                className="dropdown_nav_anchor text_styling_anchor"
              >
                About
              </button>
            </Dropdown>
          </div>
          <div className="dropdown">
            <Dropdown
              overlay={upcomingEventsItem}
              // trigger={["click"]}
            >
              <button
                style={{
                  backgroundColor: "rgba(0,0,0,0)",
                  border: "none",
                }}
                href="/#"
                // onClick={(e) => {e.preventDefault();
                className="dropdown_nav_anchor text_styling_anchor"
              >
                Upcoming Events
              </button>
            </Dropdown>
          </div>
          {/* </div> */}
          <a
            class="nav-link scrollto"
            href="#newsletter"
            style={{
              textDecoration: "none",
              color: "white",
              fontWeight: "700",
              fontSize: "1.2Rem",
            }}
          >
            NewsLetter
          </a>

          <a
            href="/gallery"
            class="nav-link scrollto"
            style={{
              textDecoration: "none",
              color: "white",
              fontWeight: "700",
              fontSize: "1.2Rem",
            }}
          >
            Gallery
          </a>

          <a
            class="nav-link "
            href="#contact"
            style={{
              textDecoration: "none",
              color: "white",
              fontWeight: "700",
              fontSize: "1.2Rem",
            }}
          >
            Contact
          </a>
        </div>
        <div className="my-account">
          {!isAuthenticated() && (
            <>
              <a
                className="nav-link scrollto"
                href="/login"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "1.2Rem",
                }}
              >
                Login
              </a>
              <a
                className="nav-link scrollto"
                href="/signup"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "1.2Rem",
                }}
              >
                Sign Up
              </a>
            </>
          )}

          {isAuthenticated() && (
            <>
              <div>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  onClick={(e) => e.preventDefault()}
                >
                  <button
                    style={{
                      backgroundColor: "rgba(0,0,0,0)",
                      border: "none",
                    }}
                    onClick={(e) => e.preventDefault()}
                    // onClick={(e) => {e.preventDefault();
                    className="dropdown_nav_anchor text_styling_anchor"
                  >
                    My Account
                  </button>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const styles = css`
  width: 100%;

  display: flex;
  align-items: center;

  .dropdown_nav_anchor {
    color: white;
  }
  @media (max-width: 1000px) {
    max-width: 100%;
    padding: 0 30px 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    :0.5 ;
    position: absolute;
    left: 0;
    top: 70px;
    background: rgba(35, 45, 57, 0.8);
    transition: top 1100ms ease-in-out, 1100ms ease-in-out;
    &.hidden {
      left: 0;
      top: -500px;
      opacity: 0.5;
    }
    .btn {
      width: 100%;
      text-align: center;
      padding: 16px;
    }
    .my-account {
      width: 200px;
    }
  }
`;

export default LinksContainer;
