import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

import "../login/login.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordReset = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/users/reset`,
        { email: email },
        { headers: headers }
      );

      if (response.status === 201) {
        toast.success("Password reset email sent successfully.");
      } else {
        toast.error("Password reset request failed. Please check your email and try again.");
      }
    } catch (error) {
      console.error("Error sending password reset request:", error);
      toast.error("Email is not Registered");
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
      <img src="/Rectangle 20.png" alt="Login Visual" />
      </div>
      <div className="login-form">
        <img src={MASTER_LOGO} alt="logo" className="logo" />
        <h2>Forgot Password</h2>
        <p>Enter your email to reset your password</p>
        <div className="input-group">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <button className="login-button" onClick={handlePasswordReset}>
          Reset Password
        </button>
        <p className="signup-link">
          Remember your password? <a href="/login">Login</a>
        </p>
      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default ForgotPassword;
