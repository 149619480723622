import React from "react";
import "../LandingPage/css/Footer.css";
import { FaInstagram, FaFacebookF, FaPinterestP, FaYoutube, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { jsx, css } from "@emotion/core";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
       <Link to="/" style={{textDecoration:"none"}}>
          <h2 css={styles}>
              <i style={{color:"#fff"}}>
                  VECTO<span style={{ color: "#0081f8" }}>G</span>
              </i>
              {/* <img src={MASTER_LOGO} alt="image10" style={{ height: "10px", }} /> */}
          </h2>
          </Link>
        <nav className="footer-nav">
          <a href="#">About</a>
          <a href="#">Help</a>
          <a href="#">Support</a>
          <a href="#">FAQ</a>
        </nav>
        <div className="social-icons">
          <FaInstagram />
          <FaFacebookF />
          <FaPinterestP />
          <FaYoutube />
          <FaTwitter />
        </div>
      </div>
      
      <hr className="footer-line" />

      <div className="footer-bottom">
        <p>Copyright 2025 © VECTOG. All rights reserved</p>
        <div className="footer-links">
          <a href="#">Privacy Policy</a>
          <a href="#">Terms and Conditions</a>
        </div>
      </div>
    </footer>
  );
};

const styles = css`
    color: #fff;
    font-size: 30px;
    font-weight: 900;
     padding: 0px 40px 0px 0px;
    line-height: 1;
    cursor: pointer;
    span {
        color: #ed563b;
    }
`;

export default Footer;
