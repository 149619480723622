import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home/Home";
import Memberfun from "./components/Main/memberfunction";
import Signup from "./components/signup/signup";
import Login from "./components/login/login";
import Gallery from "./components/gallery/gallery";
import Payment from "./components/payment/payment";
import Register from "./components/registration/register";
import UserInfo from "./components/registration/userInfo";
import FormInfo from "./components/registration/eventinfo";
import Navbar from "../src/components/Navbar/Nav";
import ThankYou from "./components/registration/thankYou";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import { AuthProvider } from "./context/authContext";
import GuardedRoute from "./context/guardedRoute";
import ProtectedRoute from "./context/protectedRoute";
import ForgotPassword from "./components/login/forgotPassword";
import SetPassword from "./components/login/setPassword";
import Profile from "./components/profile/profile";
import VerificationDone from "./components/signup/verificationDone";
import AllUserList from "./components/admin/allUserList";
import AllEventDetails from "./components/admin/allEventList";
import CreateEvent from "./components/admin/createEvent";
import AllRegisteredList from "./components/admin/allRegisteredUsers";
import EditEvent from "./components/admin/editEvent";
import SingleEventPage from "./components/registration/singleEvent";
import EventRegistration from "./components/registration/registers";
import AllContacts from "./components/admin/contact-list";
import NewsLetter from "./components/LandingPage/NewsLetter";
import NewsletterSubscriberList from "./components/admin/newsletter-subscribers";


const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <Navbar />
        
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/Member" exact element={<Memberfun />} />
          <Route path="/abcd" />
          <Route path="/signup" exact element={<Signup />} />
          
          <Route path="/admin/createevent" exact element={<CreateEvent />} />
          <Route path="/admin/eventlist" exact element={<AllEventDetails />} />
          <Route path="/admin/contact-list" exact element={<AllContacts />} />
          <Route path="/admin/users" exact element={<AllUserList />} />
          <Route path="/admin/registerdusers" exact element={<AllRegisteredList />} />
          <Route path="/admin/editEvent/:eventId" exact element={<EditEvent/>} />
          <Route path= "/admin/newsletter-subscribers" exact element={<NewsletterSubscriberList/>}/>
          <Route path="/account" exact element={<Profile />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/forgotpassword" exact element={<ForgotPassword />} />
          <Route path="/eventDetails/:eventId" exact element={<SingleEventPage/>} />
          <Route
            path="/user/confirm/:token"
            exact
            element={<VerificationDone />}
          />
          <Route
            path="/user/password/reset/:token"
            exact
            element={<SetPassword />}
          />
          {/* <GuardedRoute path="/register" component={Register} auth={false} /> */}
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/payment" exact element={<Payment />} />
          <Route path="/register/:eventId" exact element={<Register />} />
          <Route path="/registers/:id" exact element={<EventRegistration/>} />
          <Route path="/userRegistered/:id" exact element={<UserInfo />} />
          {/* <Route path="/event-details/:id" exact element={<SingleEvent />} /> */}
          {/* <Route path="/forminfo" exact element={<FormInfo />} /> */}
          <Route path="/event/:category" exact element={<FormInfo />} />
          <Route path="/thankyou" exact element={<ThankYou />} />
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
