import React from "react";
import "../../App.css"; // Ensure this contains relevant styles

const HeroSection = ({ eventsRef }) => {
  const handleScroll = () => {
    if (eventsRef?.current) {
      eventsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="hero-container" style={{ marginBottom: "40px", position: "relative", textAlign: "center" }}>
      {/* Overlay */}
      <div className="hero-overlay"></div>

      {/* Content */}
      <div className="hero-content">
        <h1 style={{ 
          color: "white", 
          fontSize: "2.5rem", 
          fontWeight: "bold", 
          maxWidth: "80%", 
          margin: "auto",
          zIndex: "2", // Ensure text is above overlay
          position: "relative"
        }}>
          Your Ultimate Destination for Aspiring Athletes and Sports Excellence
        </h1>
        <button 
       onClick={handleScroll}
          className="hero-button" 
          style={{
            backgroundColor: "#FF4D4D", 
            color: "white", 
            padding: "12px 24px", 
            borderRadius: "6px", 
            fontSize: "1rem",
            fontWeight: "bold",
            marginTop: "20px",
            border: "none",
            cursor: "pointer",
            zIndex: "2", // Ensure button is above overlay
            position: "relative"
          }}
        >
          Explore Events
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
