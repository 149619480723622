import React from "react";
import "../../App.css"; // Importing CSS file

const AboutUs = () => {
  return (
    <div id="about" className="about-container container">
      {/* Left Section - Text Content */}
      <div className="about-text">
        <h2>About Us</h2>
        <p>
          VectoG Performance aims to provide services in the field of sports and
          exercise. The wider goal of the organization is to create a
          professional environment for athletes that will aim to focus on the
          following 5 departments.
        </p>
        <ul>
          <li>Sports and exercise research</li>
          <li>Coaching Education</li>
          <li>Athlete recruitment and foreign education</li>
          <li>Infrastructure development</li>
          <li>Sports and exercise training</li>
        </ul>

        {/* Green Shadow Effect */}
        <div className="shadow-border"></div>
      </div>

      {/* Right Section - Image */}
      <div className="about-image">
        <div className="image-container">
          <img src="/333.png" alt="About Us" />
        </div>

        {/* Green Border & Shadow Effect */}
        <div className="image-shadow-border"></div>
      </div>
    </div>
  );
};

export default AboutUs;
