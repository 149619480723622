import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./signup.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Signup = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/api/users/create-user`, {
        firstName,
        lastName,
        email,
        phone,
        password,
      });
      if (res.status === 200) {
        toast.success("Signup Successful");
        setTimeout(() => navigate("/login"), 3000);
      }
    } catch (error) {
      toast.error("User already exists or signup failed");
    }
  };

  return (
    <div className="signup-container">
            <div className="login-image">
        <img src="/Rectangle 20.png" alt="Login Visual" />
      </div>
      <div className="signup-box">
        <h2>Sign Up</h2>
        <p>Let’s get you all set up so you can access your personal account.</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} required />
            <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} required />
          </div>
          <div className="input-group">
            <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
            <input type="text" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} required />
          </div>
          <div className="input-group full">
            <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
            <input type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} required />
          </div>
          <div className="terms">
            <input type="checkbox" onChange={() => setAgree(!agree)} required />
            <label>I agree to all the <a href="#">Terms</a> and <a href="#">Privacy Policies</a></label>
          </div>
          <button type="submit" className="signup-button">Create Account</button>
        </form>
        <p className="login-link">Already have an account? <a href="/login">Login</a></p>
        <div className="social-login">
          <p>Or sign up with</p>
          <div className="social-icons">
            <button className="facebook">F</button>
            <button className="google">G</button>
            <button className="apple"></button>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000} />
    </div>
  );
};

export default Signup;
