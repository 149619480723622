import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./Admin.css";
import axios from "axios";
import Cookies from "js-cookie";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";

function AllRegisteredList() {
  const [userDetails, setUserDetails] = useState([]);
  const letsGo = useNavigate();

  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    if (!isAdmin) {
      letsGo("/login");
    }
  }, [isAdmin, letsGo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/get-all-registrations`,
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        if (response?.data?.registrations) {
          setUserDetails(response?.data?.registrations);
          console.log(response?.data?.users);
        } else {
          console.error(
            "Users not found in the API response:",
            response.data.registrations
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  console.log(userDetails);

  const handleStatusChangeApprove = async (id, status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/success-register/${id}`,
        {}, // No body data is being sent, but if needed, you can pass an object here.
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("accessToken")}`, // Ensure the token is fetched correctly
          },
        }
      );
      if (response?.data) {
        console.log(response?.data);
        window.location.reload();
      } else {
        console.log("No data received");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChangeReject = async (id, status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/reject-register/${id}`,
        {},
        { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
      );
      if (response?.data) {
        console.log(response?.data);
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-user">
      <div className="event_div">
        <div className="event_btn_">
          <h2>Registered Users</h2>
        </div>
        <Table striped bordered hover size="sm" className="my_event_table">
          <thead>
            <tr>
              <th>S.NO.</th>
              <th>RegistrationId</th>
              <th>UserId</th>
              <th>Event Name</th>
              <th>TransationId</th>
              {/* <th>Amount</th> */}
              <th>Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>PaymentStatus</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userDetails?.map((user, index) => (
              <tr key={user?._id}>
                {" "}
                <td>{index + 1}</td>
                <td>{user?._id}</td>
                <td>{user?.userId}</td>
                <td>
                  {user?.event?.map((event, index) => (
                    <td>{event?.title}</td>
                  ))}
                </td>
                <td>{user?.transactionId}</td>
                {/* <td>{user?.registrationCost}</td> */}
                {/* <td>{user?.event?.title}</td>  */}
                <td>
                  {user?.firstName} {user?.lastName}{" "}
                </td>
                <td>{user?.email}</td>
                <td>{user?.phoneNo}</td>
                {/* <td>{user?.gender}</td>
                <td>{user?.organization}</td> */}
                {/* <td>{user?.address},{user?.pinCode}</td> */}
                <td>{user?.isRegistered}</td>
                <td>
                  <span
                    variant="success"
                    onClick={() =>
                      handleStatusChangeApprove(user._id, "approve")
                    }
                  >
                    <FontAwesomeIcon
                      style={{ color: "green", fontSize: "18px" }}
                      icon={faCheck}
                    />
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    variant="danger"
                    onClick={() => handleStatusChangeReject(user._id, "reject")}
                  >
                    <FontAwesomeIcon
                      style={{ color: "red", fontSize: "18px" }}
                      icon={faXmark}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default AllRegisteredList;
