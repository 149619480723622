"use client";

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CompanyLogos() {
  const companyLogos = [
    { id: 1, image: "./SponsoredByLogo/Vector.png", url: "https://www.youtube.com/channel/UCXYZ" }, // YouTube Channel ID
    { id: 2, image: "./SponsoredByLogo/Vector (1).png", url: "https://www.instagram.com/username" }, // Instagram Profile
    { id: 3, image: "./SponsoredByLogo/Vector (2).png", url: "https://www.facebook.com/username" }, // Facebook Page
    { id: 4, image: "./SponsoredByLogo/Group.png", url: "https://www.google.com/search?q=company_name" }, // Google Search
  ];

  return (
    <div
      style={{
        backgroundColor: "#C0E4C01A",
        padding: "20px",
        textAlign: "center",
        marginBottom: "40px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        <span style={{ fontWeight: "bold", color: "#555", fontSize: "18px" }}>
          Sponsored By
        </span>
        {companyLogos.map((logo) => (
          <div
            key={logo.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "60px",
            }}
          >
            {/* <a href={logo.url} target="_blank" rel="noopener noreferrer"> */}
              <img
                src={logo.image}
                alt={`Company logo ${logo.id}`}
                style={{ width: "50px", height: "50px", objectFit: "contain" }}
              />
            {/* </a> */}
          </div>
        ))}
      </div>
    </div>
  );
}
