import React, { useState, useEffect } from "react";
import "./userInfo.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Barcode from "../../Image/barcodeImage.png";
import { useAuth } from "../../context/authContext.js";

const Events = () => {
  const letsGo = useNavigate();
  const { id } = useParams();
  const{isAuthenticated} = useAuth();
  useEffect(() => {
    if (!isAuthenticated()) {
      letsGo("/login");
    }
  }, [isAuthenticated, letsGo]);

  const [user, setUser] = useState({});
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/get-specific-registration/${id}`,         
        );
        if (response?.data) {
          console.log(response?.data);
          setUser(response?.data);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    };

    
    fetchData();
  }, [id]); 

  console.log(user);


const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.patch
      (`${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/send-transaction-id/${id}`,
      {
        transactionId: transactionId,
      }
    );
    if (response?.data) {
      console.log(response?.data);
      letsGo("/thankyou");
    } else {
    }
  } catch (error) {
    console.log(error);
  }
}



  return (
    <div>
      <div className=" register">
        <div className="row">
          <div className=" category-name"></div>
          <div className="container" style={{background:"white" ,padding:"30px"}}>
            <div>
              <h2 className="register-headi">User Information</h2>
            </div>
             <div className="register-headi">
             <h4>Please make payment using QR code, Enter Payment Id and Click
              Submit</h4> 
            </div>
            <div className="row">
            <div className="col-md-6 ">
              <br />
              <div className="row register-forms">
                <label
                  className="fetcheddata"
                  name="FirstName"
                  value="FirstName"
                >
                  <b> First Name :</b> {user?.firstName}
                </label>
                <label className="fetcheddata" name="LastName" value="LastName">
                  <b> Last Name :</b> {user?.lastName}
                </label>
                <label className="fetcheddata" name="PhoneNo" value="PhoneNo">
                  <b> Phone Number :</b> {user?.phoneNo}
                </label>
                <label className="fetcheddata" name="Email" value="Email">
                  <b> Email :</b> {user?.email}
                </label>
                {/* <label className="fetcheddata" name="Amount" value="Amount">
                  <b> Total Amount :</b> {user?.registrationCost}
                </label> */}

                 <form onSubmit={handleSubmit}>
                  <div style={{display:"flex"}}>
                <label className="fetcheddata">
                  <b>Payment Id : &nbsp;</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Payment Id"
                    style={{ width: "50%" }}
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value )}
                    required
                  />
               
                </div>
<br/>
<br/>
                <div>
                  {/* *text */}
                </div>
                <div>
                <button type="submit" className="btnRegistersubmit" value="">
                  Submit
                </button>
                </div>
                </form>
              </div>
            </div>
            <div className="col-md-5"> 
            <div className=" barcodeimage">
            <img  height={600} width={500} src={Barcode}/> 
            </div>
            
            </div>
          </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Events;

// useEffect(() => {
//   getUserInfo();
// }, []);

// const getUserInfo = () => {
//   axios
//     .get(
//       `${process.env.REACT_APP_BACKEND_API_URL}/user/${localStorage.getItem(
//         "id"
//       )}`
//     )
//     .then((x) => {
//       setUser(x?.data?.response[0]);
//     });
// };

// const checkout = () => {
//   axios
//     .post(`${process.env.REACT_APP_BACKEND_API_URL}/order`, {
//       amount: user.amount,
//     })
//     .then((results) => {
//       setOrders(results?.data?.sub?.id);
//     });
//   const script = document.createElement("script");
//   script.src = "https://checkout.razorpay.com/v1/checkout.js";
//   script.onerror = () => {
//     alert("Razorpay SDK failed to load. Are you online?");
//   };
//   script.onload = async () => {
//     try {
//       setLoading(true);
//       const result = await axios.post(
//         `${process.env.REACT_APP_BACKEND_API_URL}/order`,
//         {
//           amount: user?.amount * 100,
//         }
//       );
//       const { id } = result?.data?.sub;
//       const {
//         data: { key: razorpayKey },
//       } = await axios.get(
//         `${process.env.REACT_APP_BACKEND_API_URL}/get-razorpay-key`
//       );

//       const options = {
//         key: razorpayKey,
//         amount: user?.amount * 100,
//         currency: "INR",
//         name: user?.FirstName + " " + user?.LastName,
//         description: "VectoG transaction",
//         order_id: id,
//         payment_capture: "1",
//         handler: async function (response) {
//           const result = await axios.post(
//             `${
//               process.env.REACT_APP_BACKEND_API_URL
//             }/pay-order/${localStorage.getItem("id")}`,
//             {
//               amount: user?.amount,
//               razorpayPaymentId: response?.razorpay_payment_id,
//               razorpayOrderId: response?.razorpay_order_id,
//               razorpaySignature: response?.razorpay_signature,
//             }
//           );

//           letsGo("/thankyou");
//         },
//         prefill: {
//           name: user?.FirstName + " " + user?.LastName,
//           email: user?.Email,
//           contact: user?.PhoneNo,
//         },
//         theme: {
//           color: "#80c0f0",
//         },
//       };

//       setLoading(false);
//       const paymentObject = new window.Razorpay(options);
//       paymentObject.open();
//     } catch (err) {
//       alert(err);
//       setLoading(false);
//     }
//   };
//   document.body.appendChild(script);
// };
