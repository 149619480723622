import React, { useRef } from "react";

import Section1 from "../LandingPage/Section1";
import Section2 from "../LandingPage/Section2";
import Section3 from "../LandingPage/Section3";
import Section4 from "../LandingPage/Section4";
import OurFeature from "../LandingPage/upcomingEvents";
import CompanyLogos from "../LandingPage/SponsoredBy";
import GamingCommunity from "../LandingPage/NewsLetter";
import Footer from "../LandingPage/Footer";

const Home = () => {
  const eventsRef = useRef(null);

  return (
    <div>
      <Section1 eventsRef={eventsRef}/>
      <OurFeature ref={eventsRef}/>
      <CompanyLogos />
      <Section2 />
      <Section3 />
      <Section4 />
      <GamingCommunity />
      <Footer />
    </div>
  );
};

export default Home;
