import React, { useEffect,forwardRef, useState } from "react";
import { Carousel } from "primereact/carousel";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";

const CarouselComponent = forwardRef((props, ref) => { 
  const navigate = useNavigate();
  const letsGo = useNavigate();
 

  const { allEventDetails } = useAuth(); // Get event details from AuthContext
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (allEventDetails.length > 0) {
      setEvents(allEventDetails);
    }
  }, [allEventDetails]);

  console.log(events);
  const moveToRegister = (eventId) => {
    letsGo("/registers/" + eventId);
  };

  const itemTemplate = (slide) => (
    <div
      style={{
        margin: "12px",
        background: "white",
        // padding: "16px",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        width: "100%",
      }}
    >
      <img
        src={
          slide.file
            ? `${process.env.REACT_APP_BACKEND_API_URL}/image/${slide.file}`
            : "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?w=600"
        }
        alt={slide.title}
        onError={(e) =>
          (e.target.src =
            "https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?w=600")
        }
        style={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />

      <div style={{ padding: "16px", textAlign: "left" }}>
        <h3
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#333",
            marginBottom: "4px",
          }}
        >
          {slide.title}
        </h3>
        <p style={{ fontSize: "14px", color: "#666", marginBottom: "10px" }}>
          {slide.description || "Event description goes here."}
        </p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#333",
              marginBottom: "0px",
            }}
          >
            Status:{" "}
            <span
              style={{
                color:
                  slide.status === "live"
                    ? "#28a745"
                    : slide.status === "upcoming"
                    ? "#007bff"
                    : "#dc3545",
              }}
            >
              {slide.status}
            </span>
          </p>
          <p style={{ fontSize: "14px", color: "#666", marginBottom: "0px" }}>
            <span style={{ fontWeight: "bold" }}>startDate:</span>{" "}
            {slide.startDate.split("T")[0]}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <button
            onClick={() => navigate(`/eventDetails/${slide._id}`)}
            style={{
              marginTop: "12px",
              border: "2px solid #FE6347",
              color: "#FE6347",
              padding: "10px 40px",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "600",
              borderRadius: "6px",
              transition: "0.3s",
              backgroundColor: "white",
            }}
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
     {
      events.length > 0 && (
        <div
        ref={ref}
        style={{
          width: "100%",
          maxWidth: "1280px",
          margin: "0 auto",
          padding: "0 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        <h2
        className="desktop-heading"
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "50px",
            paddingLeft: "60px", 
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            fontWeight: "300",

          }}
        >
          Upcoming Events
        </h2>
        <div style={{ width: "100%" }}>
          <Carousel
            value={events}
            itemTemplate={itemTemplate}
            numVisible={3}
            numScroll={1}
            responsiveOptions={[
              { breakpoint: "1200px", numVisible: 3, numScroll: 1 },
              { breakpoint: "1024px", numVisible: 2, numScroll: 1 },
              { breakpoint: "768px", numVisible: 1, numScroll: 1 },
            ]}
            circular
            //   autoplayInterval={3000}
            showIndicators={false} // Hides dots
            showNavigators={true} // Shows arrows
            contentClassName="custom-carousel"
          />
        </div>
      </div>
      )
     }

      <style>
        {`
          /* Apply padding-left only on desktop screens */
          @media (max-width: 1024px) {
            .desktop-heading {
              padding-left: 0px; /* Remove padding on smaller screens */
            }
          }

          /* Custom styles for carousel */
          .custom-carousel .p-carousel-item {
  padding: 15px; 
}
  .custom-carousel {
  }
        `}
      </style>
      
    </>
  );
});


export default CarouselComponent;
